/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  padding: 20px;
  margin-top: -115px;
}

 .mat-progress-spinner circle, .mat-spinner circle {
  stroke: white
}
